<template>
    <section class="hero section section-0 is-fullheight-with-navbar">
        <div class="hero-body section-content-0 has-text-centered is-align-items-flex-end">
            <div class="container">
                <div class="mt-3">
                    <h2 class="title is-2 is-size-2-mobile has-text-weight-semibold">Digital Avatars</h2>
                    <h5 class="subtitle is-5 is-size-6-mobile">And first interactive 3D clothing mockup <br> builder in your browser</h5>
                </div>
                <img src="img/cover_2.jpg" class="is-hidden-tablet"/>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted () {
            if (!this.isMobile) {
                this.$nextTick(this.pinScene);
            }
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            pinScene () {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.section-content-0`,
                    1.2,
                    {y: 25},
                    {y: -25, ease: this.$gsap.Linear.easeNone},
                );

                tl.to(
                    `section.section-0`,
                    0.5,
                    {opacity: 0, delay: 0.5},
                    '-=0.8'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.pinContainer',
                    triggerHook: 'onLeave',
                    duration: `100%`
                })
                    .setPin('.pinContainer')
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainerScene', scene);
            }
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (min-width: 768px) {
        .hero-body {
            align-items: center !important;
        }
    }

    @media only screen and (max-width: 768px) {
        .hero {
            padding-bottom: 0;

            .hero-body {
                padding-bottom: 0;
            }
        }
    }
</style>
