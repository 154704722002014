<template>
    <footer class="footer has-background-black">
        <div class="columns">
            <div class="column is-one-third-desktop">
                <div class="content has-text-centered-mobile">
                    <p>
                        QLO by <a href="https://morflax.com">Morflax</a>
                    </p>
                </div>
            </div>
            <div class="column is-one-third-desktop">
                <div class="content">
                    <div class="is-justify-content-flex-end has-text-centered has-text-centered-mobile">
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://instagram.com/morflax_"
                                  target="_blank"
                                  icon-right="instagram-line"
                        ></b-button>
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://twitter.com/morflax_com"
                                  target="_blank"
                                  icon-right="twitter-line"
                        ></b-button>
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://facebook.com/morflax"
                                  target="_blank"
                                  icon-right="facebook-circle-line"
                        ></b-button>
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://dribbble.com/morflax"
                                  target="_blank"
                                  icon-right="dribbble-line"
                        ></b-button>
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://www.behance.net/morflax"
                                  target="_blank"
                                  icon-right="behance-line"
                        ></b-button>
                        <b-button rounded
                                  class="is-text"
                                  tag="a"
                                  href="https://pinterest.com/morflax_"
                                  target="_blank"
                                  icon-right="pinterest-line"
                        ></b-button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "MainFooter"
    }
</script>
