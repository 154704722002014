<template>
    <b-modal v-model="isWaitingList" full-screen>
        <section class="hero section is-fullheight has-background-white">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <div class="mailing-list">
                        <div class="mb-6">
                            <h2 class="title is-4 has-text-weight-bold has-text-centered has-text-black">Join our mailing list</h2>
                            <h6 class="subtitle is-6 has-text-centered">
                                Waiting for new features and improvements? Join our waiting list to get early access when we
                                launch QLO 2.0
                            </h6>
                        </div>
                        <subscription></subscription>
                    </div>
                </div>
            </div>
        </section>
    </b-modal>
</template>

<script>
    import Subscription from "./Subscription";
    import EventBus from "../../plugins/EventBus";

    export default {
        name: "SubscriptionModal",

        components: {
            Subscription,
        },

        data() {
            return {
                isWaitingList: false,
            };
        },

        mounted() {
            EventBus.$on('open-modal', () => {
                this.isWaitingList = true;
            });
        },
    }
</script>

<style scoped>
    .mailing-list {
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and (max-width: 768px) {
        .mailing-list {
            width: 100%;
        }
    }
</style>
