<template>
    <section class="section-11 pb-6 pt-6 has-background-white">
        <div class="section-content-11">
            <div class="container has-text-centered px-3">
                <div>
                    <h4 class="title is-4 has-text-weight-semibold has-text-black">
                        Start your digital journey
                    </h4>
                    <h5 class="subtitle is-5 is-size-5-mobile has-text-weight-semibold has-text-grey-dark">
                        Select your character and get your free mockups
                    </h5>
                </div>
                <div class="is-flex-tablet is-justify-content-center mt-6">
                    <div v-for="(avatar, index) in avatars"
                         :key="index"
                         class="card mr-5 mb-5"
                         :class="resolveCardStyle(avatar)"
                         :style="{'background-image':'url(' + getAvatarImage(avatar) + ')'}"
                         @click="openBeta(avatar.name)"
                    >
                        <footer class="card-content">
                            <div class="has-text-left">
                                <h6 class="subtitle is-6 has-text-weight-semibold has-text-white">Digital avatar</h6>
                                <h4 class="title is-4 has-text-weight-semibold">{{ avatar.title }}</h4>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "selection-hero",

        data() {
            return {
                show: false,
                avatars: [
                    {
                        name: 'model-x',
                        title: 'Model X',
                        selected: false,
                        image: 'model-x-selection3.jpg',
                    },
                    {
                        name: 'model-y',
                        title: 'Model Y',
                        selected: false,
                        image: 'model-y-selection.jpg',
                    }
                ]
            }
        },

        mounted() {
            this.show = true;

            // this.$nextTick(this.pinScene)
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                // tl.from(
                //     `section.section-11`,
                //     1,
                //     {opacity: 0},
                // );

                tl.fromTo(
                    `.section-content-11`,
                    5,
                    {y: 25},
                    {y: -25, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.to(
                    `section.section-11`,
                    1,
                    {opacity: 0, delay: 0.5},
                    '-=1'
                );


                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.pinContainer5',
                    triggerHook: 'onLeave',
                    duration: `100%`,
                })
                    .setPin('.pinContainer5')
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer5Scene', scene);
            },

            getAvatarImage(avatar) {
                return require('../../../public/img/' + avatar.image);
            },

            resolveCardStyle(avatar) {
                let components = [];

                if (avatar.selected) {
                    components.push('card-active')
                }

                components.push(avatar.name);

                return components;
            },

            openBeta() {
                // TODO: temp solution
                // window.open(`https://qlo.morflax.com/app/#/customize/${name}`)

                window.open('https://morflax.com/qlo-beta-access/');
            },
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    .hero {
        text-align: center;
        margin-top: -30%;
    }

    .card {
        height: 500px;
        width: 350px;
        /*background-position: top center;*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
        /*box-shadow: 0 0 20px 0 #0000009c;*/

        .card-footer {
            margin-bottom: 0;
            width: 100%;
            position: absolute;
            bottom: 0;

            .card-footer-title {
                border-width: 0;
                padding: 2rem;
            }
        }

        &.model-y {
            &:hover {
                opacity: 1;
                box-shadow: 0 2px 28px 0 rgb(0 0 0 / 50%);
            }
        }

        &.model-x {
            &:hover {
                opacity: 1;
                box-shadow: 0 2px 28px 0 rgb(0 0 0 / 50%);
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .section .is-flex {
            display: block !important;
        }

        .card {
            height: 250px !important;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
