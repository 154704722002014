<template>
    <mailchimp-subscribe
            url="https://morflax.us2.list-manage.com/subscribe/post-json"
            user-id="8be0bf8723d1262158cc94666"
            list-id="e42a86a1c1"
            @error="onError"
            @success="onSuccess"
    >
        <template v-slot="{ subscribe, setEmail, error, success, loading }">
            <form @submit.prevent="subscribe">
                <b-field position="is-centered">
                    <b-input v-model="email" placeholder="Email" type="email" @input="setEmail(email)" expanded></b-input>
                    <p class="control">
                        <b-button type="is-black" @click="subscribe">Subscribe</b-button>
                    </p>
                </b-field>
                <div v-if="error" v-html="error"></div>
                <div v-if="success">Yay!</div>
                <div v-if="loading">Loading…</div>
            </form>
        </template>
    </mailchimp-subscribe>
</template>

<script>
    import MailchimpSubscribe from 'vue-mailchimp-subscribe'

    export default {
        data() {
            return {
                // error: null,
                // success: false,
                // loading: false,
                email: '',
            };
        },

        components: {
            MailchimpSubscribe,
        },

        methods: {
            onError() {
                // handle error
            },

            onSuccess() {
                // handle success
            },

            // setEmail(value) {
            //     this.email = value;
            // }
        },
    }
</script>
