<template>
    <div>
        <section class="section-6 has-background-light py-6">
            <div class="is-align-items-flex-end p-0 mx-3">
                <div class="container has-text-centered">
                    <h3 class="title has-text-weight-semibold is-3 mb-6 has-text-black">
                        Where to use?
                    </h3>
                    <p class="title has-text-weight-semibold is-6 has-text-black mx-6">
                        1. APPS, SERVICES
                    </p>
                    <div class="columns is-variable is-8-tablet mt-6 mb-6">
                        <div class="column">
                            <img src="img/app_1_screen.jpg" class="where app-1-screen"/>
                        </div>
                        <div class="column">
                            <img src="img/app_2_screen.jpg" class="where app-2-screen"/>
                        </div>
                        <div class="column">
                            <img src="img/app_3_screen.jpg" class="where app-3-screen"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-6-1 has-background-light py-6">
            <div class="is-align-items-flex-end p-0 mx-3 my-2">
                <div class="container py-6">
                    <p class="title has-text-weight-semibold is-6 has-text-black has-text-centered">
                        2. E-COMMERCE WEBSITES
                    </p>
                    <img src="img/where_1.jpg" class="where where-2 where-1-card is-flex is-justify-content-center mb-3"/>
                    <img src="img/where_2.jpg" class="where where-2 where-2-card is-flex is-justify-content-center"/>
                </div>
            </div>
        </section>
        <section class="section-6-2 has-background-light py-6">
            <div class="is-align-items-flex-end p-0 mx-3 my-2">
                <div class="container py-6">
                    <p class="title has-text-weight-semibold is-6 has-text-black has-text-centered">
                        3. SOCIAL MEDIA & ADVERTISEMENT
                    </p>
                    <div class="columns is-variable is-3 mt-6 mb-6 mx-6 is-multiline">
                        <div class="column is-half-tablet mb-6">
                            <img src="img/insta_2.jpg" class="where where-3 insta-3"/>
                        </div>
                        <div class="column is-half-tablet mb-6">
                            <img src="img/insta_1.jpg" class="where where-3 insta-2"/>
                        </div>
                    </div>
<!--                    <div class="columns is-variable is-3 mt-6 mb-6 mx-6 is-multiline">-->
<!--                        <div class="column is-one-third-tablet insta-4">-->
<!--                            <img src="img/insta_7.jpg" class="where where-3 mb-3"/>-->
<!--                        </div>-->
<!--                        <div class="column is-one-third-tablet insta-5">-->
<!--                            <img src="img/insta_9.jpg" class="where where-3 mb-3"/>-->
<!--                        </div>-->
<!--                        <div class="column is-one-third-tablet insta-2">-->
<!--                            <img src="img/insta_10.jpg" class="where where-3 mb-3"/>-->
<!--                            <img src="img/insta_6.jpg" class="where where-3 mb-3"/>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="columns is-variable is-3 mt-6 mb-6 mx-6 is-multiline">
                        <div class="column is-two-third-tablet insta-4">
<!--                            <img src="img/add.jpg" class="where where-3 mb-3"/>-->
                            <img src="img/insta_10.jpg" class="where where-3 mb-3"/>
                        </div>
                        <div class="column is-one-third-tablet insta-2">
                            <img src="img/insta_7.jpg" class="where where-3 mb-3"/>
                            <img src="img/insta_9.jpg" class="where where-3 mb-3"/>
                        </div>
<!--                        <div class="column is-one-third-tablet insta-5">-->
<!--                            <img src="img/insta_9.jpg" class="where where-3 mb-3"/>-->
<!--                        </div>-->
                    </div>
                    <div class="columns is-variable is-3 mb-6 mx-6 is-multiline">
                        <div class="column insta-1">
                            <img src="img/add.jpg" class="where where-3 mb-3"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "where-to-use",

        mounted () {
            if (!this.isMobile) {
                this.$nextTick(this.pinScene);
                this.$nextTick(this.pinScene2);
                this.$nextTick(this.pinScene3);
            }
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.app-3-screen`,
                    1,
                    {y: 50},
                    {y: 0, ease: this.$gsap.Linear.easeNone},
                );

                tl.fromTo(
                    `.app-2-screen`,
                    1,
                    {y: 170},
                    {y: 20, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.fromTo(
                    `.app-1-screen`,
                    1,
                    {y: 300},
                    {y: 70, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-6',
                    triggerHook: '1',
                    duration: '200%',
                    offset: '-100%'
                })
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer6Scene', scene);
            },

            pinScene2() {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.where-1-card`,
                    1,
                    {y: 120},
                    {y: 0, ease: this.$gsap.Linear.easeNone},
                    '-=0.7'
                );

                tl.fromTo(
                    `.where-2-card`,
                    1,
                    {y: 250},
                    {y: 25, ease: this.$gsap.Linear.easeNone},
                    '-=0.7'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-6-1',
                    triggerHook: '1',
                    duration: '200%',
                    offset: '-150%'
                })
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer6-1Scene', scene);
            },

            pinScene3() {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.insta-1`,
                    1,
                    {y: 200},
                    {y: 0, ease: this.$gsap.Linear.easeNone},
                    '-=0.7'
                );

                tl.fromTo(
                    `.insta-2`,
                    1,
                    {y: 120},
                    {y: 0, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.fromTo(
                    `.insta-3`,
                    1,
                    {y: 400},
                    {y: 50, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.fromTo(
                    `.insta-4`,
                    1,
                    {y: 600},
                    {y: 0, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.fromTo(
                    `.insta-5`,
                    1,
                    {y: 500},
                    {y: 50, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-6-2',
                    triggerHook: '1',
                    duration: '300%',
                    offset: '-150%'
                })
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer6-2Scene', scene);
            },
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>

    .where {
        /*-webkit-box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);*/
        /*box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);*/
        border-radius: 1rem;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .where-2 {
        width: 90%;
    }

    .where-3 {
        width: 100%;
    }


    @media only screen and (max-width: 768px) {
        .where {
            width: 100%;
        }

        .section-6-2 .columns {
            margin: 0 !important;

            .column {
                margin: 0 !important;
            }
        }
    }

    .hero-body {
        width: 100%;
    }

    .column-text {
        align-items: center;
        display: flex;
    }


</style>
