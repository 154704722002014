<template>
<!--    <b-navbar transparent :mobile-burger="false" :collapsible="false" :class="{navbar_black: scrollPosition > 2050}">-->
    <b-navbar transparent :mobile-burger="false" :collapsible="false">
        <template slot="brand">
            <template>
                <b-navbar-item href="/" tag="a" class="px-0 has-text-white px-4 py-1">
                    <svg viewBox="0 0 1078 222" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo">
                        <path d="M894.165 51.9773V219.955C894.197 220.189 894.174 220.428 894.099 220.651C894.023 220.875 893.898 221.078 893.731 221.245C893.565 221.412 893.362 221.537 893.139 221.612C892.916 221.687 892.679 221.709 892.447 221.676H883.875C882.566 221.676 881.915 221.095 881.915 219.955V163.551C867.864 170.78 853.572 174.393 839.038 174.388C825.318 174.388 815.518 171.187 809.638 164.786C803.758 158.385 800.813 148.041 800.804 133.753V88.9063C800.804 74.467 803.744 64.0419 809.624 57.631C815.503 51.2201 825.303 48.0195 839.023 48.0293C853.723 48.0293 868.105 51.7225 882.171 59.1087L885.828 51.2274C885.989 50.4114 886.64 49.9923 887.788 49.9923H892.439C893.587 50.007 894.165 50.6687 894.165 51.9773ZM881.915 153.435V69.2177C869.299 62.8447 855.365 59.555 841.246 59.616C830.135 59.616 822.663 61.7922 818.831 66.1446C814.999 70.497 813.078 78.8292 813.068 91.1413V131.533C813.068 143.688 814.989 151.979 818.831 156.405C822.673 160.831 830.145 163.049 841.246 163.058C855.352 163.007 869.261 159.724 881.915 153.457V153.435Z" fill="currentColor"/>
                        <path d="M933.365 170.699V1.73542C933.332 1.50178 933.353 1.26364 933.428 1.03981C933.502 0.815982 933.627 0.612604 933.793 0.445742C933.959 0.278881 934.161 0.153109 934.384 0.0783625C934.607 0.00361636 934.844 -0.0180554 935.076 0.0150593H943.896C944.128 -0.0164991 944.364 0.00625701 944.586 0.081554C944.808 0.156851 945.01 0.282654 945.176 0.449159C945.341 0.615663 945.466 0.81837 945.541 1.04148C945.616 1.26459 945.639 1.50207 945.607 1.73542V170.699C945.639 170.933 945.616 171.171 945.541 171.395C945.466 171.618 945.34 171.821 945.173 171.988C945.007 172.155 944.804 172.28 944.581 172.355C944.358 172.43 944.121 172.452 943.889 172.419H935.076C934.844 172.452 934.607 172.43 934.384 172.356C934.161 172.281 933.959 172.155 933.793 171.988C933.627 171.821 933.502 171.618 933.428 171.394C933.353 171.17 933.332 170.932 933.365 170.699V170.699Z" fill="currentColor"/>
                        <path d="M982.355 134.739V87.6863C982.355 73.399 985.868 63.2189 992.894 57.1462C999.92 51.0735 1012.09 48.0371 1029.39 48.0371H1030.86C1048.17 48.0371 1060.3 51.0735 1067.24 57.1462C1074.18 63.2189 1077.65 73.399 1077.65 87.6863V134.739C1077.65 149.016 1074.18 159.197 1067.24 165.279C1060.3 171.362 1048.17 174.398 1030.86 174.388H1029.39C1012.09 174.388 999.92 171.352 992.894 165.279C985.868 159.206 982.355 149.026 982.355 134.739ZM1029.39 163.059H1030.86C1044.42 163.059 1053.56 161.047 1058.3 157.023C1063.03 152.999 1065.4 145.571 1065.41 134.739V87.6863C1065.41 76.8495 1063.04 69.4608 1058.3 65.5201C1053.56 61.5794 1044.41 59.6189 1030.86 59.6385H1029.39C1015.67 59.6385 1006.45 61.5991 1001.71 65.5201C996.98 69.4412 994.61 76.8323 994.605 87.6937V134.746C994.605 145.583 996.975 153.011 1001.71 157.03C1006.45 161.049 1015.68 163.059 1029.39 163.059Z" fill="currentColor"/>
                        <path d="M370.278 73.0871H363.279C352.309 73.0871 343.19 77.8438 335.92 82.5123V172.339H311.172L310.36 54.1263H327.393L333.141 63.6177C341.392 56.1092 352.173 52.0332 363.301 52.2148H370.278V73.0871Z" fill="currentColor"/>
                        <path d="M471.121 4.79492H496.059V172.42H471.121V4.79492Z" fill="currentColor"/>
                        <path d="M0 54.0245L15.1165 54.1862C15.6269 54.1761 16.1309 54.3004 16.5787 54.5467C17.0264 54.7931 17.4023 55.1529 17.6689 55.5904L22.0568 62.5895C33.3108 55.8788 46.1527 52.3339 59.2375 52.3262C73.162 52.3262 82.8302 56.2129 88.242 63.9864C100.087 56.3688 113.855 52.3224 127.917 52.3262C140.442 52.3262 149.53 55.512 155.18 61.8837C160.831 68.2555 163.654 78.6757 163.649 93.1443L163.583 172.347C156.873 172.361 150.432 169.695 145.676 164.936C140.92 160.177 138.238 153.714 138.221 146.968L138.082 96.1734C138.082 87.6255 137.078 82.0674 135.069 79.4991C133.06 76.9308 128.806 75.6466 122.307 75.6466C112.632 75.6969 103.073 77.7621 94.2316 81.712C94.7164 85.503 94.9485 89.3223 94.9263 93.1443L95.1677 172.347C88.3634 172.349 81.8364 169.637 77.0195 164.805C72.2027 159.974 69.4897 153.419 69.4761 146.579L69.3957 96.1734C69.3957 87.6255 68.3913 82.0674 66.3826 79.4991C64.3739 76.9308 60.12 75.6466 53.6209 75.6466C43.9634 75.7049 34.4222 77.7699 25.5965 81.712L25.6769 172.347C22.3073 172.352 18.9698 171.689 15.8552 170.396C12.7407 169.104 9.9101 167.206 7.52539 164.813C5.14069 162.42 3.24861 159.578 1.95735 156.449C0.666084 153.32 0.000957669 149.966 0 146.579L0 54.0245Z" fill="currentColor"/>
                        <path d="M185.684 133.959V92.6485C185.684 78.6601 189.513 68.4359 197.173 61.976C204.832 55.5161 217.477 52.291 235.107 52.3008H236.496C254.131 52.3008 266.778 55.5259 274.438 61.976C282.097 68.4261 285.934 78.6503 285.949 92.6485V133.937C285.949 147.93 282.121 158.155 274.467 164.61C266.812 171.065 254.165 174.29 236.525 174.285H235.136C217.501 174.285 204.857 171.06 197.202 164.61C189.547 158.159 185.708 147.943 185.684 133.959ZM235.107 150.986H236.496C246.091 150.986 252.473 149.781 255.642 147.369C258.811 144.958 260.396 140.488 260.396 133.959V92.6485C260.396 86.2767 258.772 81.8459 255.525 79.3561C252.278 76.8662 245.94 75.6213 236.511 75.6213H235.121C225.687 75.6213 219.349 76.8662 216.107 79.3561C212.865 81.8459 211.231 86.2767 211.207 92.6485V133.937C211.207 140.466 212.794 144.936 215.968 147.347C219.142 149.759 225.521 150.972 235.107 150.986Z" fill="currentColor"/>
                        <path d="M618.52 91.5122V172.45H603.44C602.709 172.45 601.678 172.347 601.678 172.347C601.678 172.347 600.903 171.252 600.654 170.811L596.478 164.047C585.961 170.909 573.897 174.34 560.285 174.34C533.679 174.34 520.376 162.988 520.376 140.285V136.322C520.376 125.598 523.545 117.707 529.883 112.649C536.221 107.591 546.353 105.064 560.277 105.069H593.187V91.5416C593.187 85.4787 591.678 81.2782 588.66 78.9403C585.642 76.6024 580.189 75.4359 572.3 75.4408H570.911C563.173 75.4408 557.798 76.6465 554.785 79.058C551.772 81.4694 550.263 85.5522 550.258 91.3063V93.8722H527.294H525.1C525.1 93.8722 524.961 92.3356 524.961 91.7548V91.2916C524.961 78.0581 528.635 68.2555 535.983 61.8837C543.33 55.512 554.97 52.3262 570.903 52.3262H572.293C588.382 52.3262 600.101 55.512 607.448 61.8837C614.795 68.2555 618.486 78.1316 618.52 91.5122ZM564.226 152.857C574.232 152.867 584.122 150.718 593.231 146.556V124.162H559.122C554.324 124.162 550.882 125.143 548.795 127.103C546.709 129.064 545.665 132.134 545.665 136.315V140.278C545.665 144.944 547.018 148.211 549.724 150.078C552.43 151.945 557.264 152.872 564.226 152.857Z" fill="currentColor"/>
                        <path d="M631.8 172.125L672.901 113.97L630.85 54.1621H660.951C664.613 59.284 668.793 65.3273 673.493 72.2921C678.193 79.2569 683.41 87.3048 689.144 96.436L716.144 54.1621H745.536L703.719 113.713L744.82 172.08H714.711C710.25 165.993 705.75 159.548 701.211 152.745C696.672 145.942 692.013 138.695 687.235 131.005L660.71 172.125H631.8Z" fill="currentColor"/>
                        <path d="M440.194 27.0942C433.665 27.0942 429.034 28.7729 426.298 32.1303C423.578 35.4901 422.225 38.3206 422.225 44.5698V52.2747H449.284V72.897H422.225L422.02 172.384H397.111L397.309 72.897H380.759V52.3115H397.301V44.5698C397.301 30.3511 400.834 21.7787 407.913 14.9119C414.992 8.04519 424.573 4.61917 436.698 4.61917C441.091 4.61472 445.472 5.08052 449.767 6.0087V28.0646C446.623 27.3754 443.411 27.0498 440.194 27.0942Z" fill="currentColor"/>
                    </svg>
                </b-navbar-item>
            </template>
        </template>
        <template slot="end">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <b-button type="is-white"
                              rounded
                              tag="a"
                              href="https://qlo.morflax.com/app"
                              target="_blank"
                    >
                        Try now
                    </b-button>
<!--                    <a href="https://morflax.com">-->
<!--                        <img src="img/morflax_logo.png" alt="Morflax creative studio" class="brand-side mb-2">-->
<!--                    </a>-->
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
    export default {
        name: "navbar",

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },

        // data() {
        //     return {
        //         scrollPosition: null
        //     }
        // },
        //
        // mounted() {
        //     window.addEventListener('scroll', this.updateScroll);
        // },
        //
        // methods: {
        //     updateScroll() {
        //         this.scrollPosition = window.scrollY
        //     }
        // }
    }
</script>

<style lang="scss">
    .navbar {
        position: fixed;
        width: 100%;
        top: 0;
    }

    .logo {
        height: 2rem;
    }

    @media only screen and (max-width: 768px) {
        .navbar {
            background-color: #000000;
            display: flex;

            .navbar-menu {
                display: block;
                box-shadow: unset;
                margin-right: 0;
                margin-left: auto;
            }
        }

        .disable-on-mobile {
            display: none;
        }
    }

    /*.brand-main {*/
    /*    padding: 0.75rem 0;*/
    /*    text-align: left;*/
    /*    color: black;*/
    /*}*/

    // New
    .brand-main {
        /*padding: 0.05rem 1rem;*/
        /*margin-bottom: 0.5rem!important;*/

        &.logo {
            font-family: Jost, Helvetica, Arial, sans-serif;
            letter-spacing: 2px;
            font-weight: 400!important;
        }
    }

    .is-vertical-border-right {
        border-right: 1px solid #dee5ed;
        position: absolute;
        height: 50%;
    }

    .brand-side {
        padding: 1rem 1rem;
    }
</style>
