<template>
    <div>
        <section class="about-hero hero hero section-4 is-black is-fullheight-with-navbar">
            <div class="hero-body about-hero-parent is-align-items-flex-end p-0">
                <div class="container">
                    <div>
                        <div class="about-hero-title has-text-centered is-hidden-mobile">
                            <h2 class="title has-text-weight-semibold is-2 is-size-3-mobile">
                                Bring your clothing <br> design to digital world
                            </h2>
<!--                            <p class="is-6">-->
<!--                                QLO is still very early but, we would love to see what you can build with it at-->
<!--                                <br> this stage! Feel free to tag us on Twitter or IG with your work.-->
<!--                            </p>-->
                        </div>
                        <div class="about-hero-title has-text-centered section-content-3 is-mobile is-hidden-tablet">
                            <h2 class="title has-text-weight-semibold is-2 is-size-4-mobile">
                                Bring your clothing design to digital world
                            </h2>
                        </div>
                        <img src="/img/avatars.png" class="about-hero-image hero-body p-0 section-content-3"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "about-hero",

        mounted () {
            if (!this.isMobile) {
                this.$nextTick(this.pinScene)
            }
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },


        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.about-hero-title`,
                    1,
                    {y: -25},
                    {y: 70, ease: this.$gsap.Linear.easeNone},
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-4',
                    triggerHook: '1',
                    duration: '200%',
                })
                    .setTween(tl);
                //
                //
                // const scene = new this.$scrollmagic.Scene({
                //     triggerElement: '.pinContainer3',
                //     triggerHook: 'onLeave',
                //     duration: `100%`,
                // })
                //     // .setPin('.pinContainer3', {pushFollowers: false}) TODO: return after AppHero.vue integration
                //     .setPin('.pinContainer3')
                //     .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer4Scene', scene);
            }
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    .column-text {
        align-items: center;
        display: flex;
    }

    .about-hero-image {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        z-index: 99;
        position: relative;
    }

    @media only screen and (max-width: 768px) {
        .about-hero-image {
            width: 100%;
        }
    }
</style>
