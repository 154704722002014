<template>
<!--    <section class="has-background-black py-6">-->
<!--            <div class="container has-text-centered px-3 py-6">-->
<!--                <div class="columns">-->
<!--                    <div class="column is-three-fifths is-fullwidth-mobile">-->
<!--                        <div class="card has-background-light">-->
<!--                            <div class="card-content has-text-black has-text-centered">-->
<!--                                <div class="has-text-centered mb-6">-->
<!--                                    <h4 class="title is-4 has-text-weight-bold has-text-black">-->
<!--                                        Free beta-->
<!--                                    </h4>-->
<!--                                    <p class="subtitle is-6">Start your digital journey</p>-->
<!--                                </div>-->

<!--                                <div class="has-text-centered">-->
<!--                                    <img src="img/macbook_screen_3.png"/>-->
<!--                                </div>-->

<!--&lt;!&ndash;                                <p class="subtitle is-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                    QLO is still very early but, we would love to see what you can build with it at&ndash;&gt;-->
<!--&lt;!&ndash;                                    <br>&ndash;&gt;-->
<!--&lt;!&ndash;                                    this stage! Feel free to tag us on Twitter or IG with your work.&ndash;&gt;-->
<!--&lt;!&ndash;                                </p>&ndash;&gt;-->

<!--                                <b-button type="is-black"-->
<!--                                          class="remove-shadow mt-6"-->
<!--                                          rounded-->
<!--                                          tag="a"-->
<!--                                          href="https://qlo.morflax.com/app"-->
<!--                                          target="_blank"-->
<!--                                >-->
<!--                                    Try beta-->
<!--                                </b-button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="column is-two-fifths is-fullwidth-mobile">-->
<!--                        <div class="card has-background-light">-->
<!--                            <div class="card-content has-text-black has-text-centered">-->
<!--                                <div class="has-text-centered mb-6">-->
<!--                                    <h4 class="title is-4 has-text-weight-bold has-text-black">-->
<!--                                        Personal solution-->
<!--                                    </h4>-->
<!--                                    <p class="subtitle is-6">Wan't something unique? Contact us</p>-->
<!--                                </div>-->

<!--                                <div class="has-text-centered">-->
<!--                                    <img src="img/footer_card_2.jpg"/>-->
<!--                                </div>-->

<!--                                <b-button type="is-black"-->
<!--                                          class="remove-shadow mt-6"-->
<!--                                          rounded-->
<!--                                          tag="a"-->
<!--                                          href="https://morflax.com/projects/premium/"-->
<!--                                          target="_blank"-->
<!--                                >-->
<!--                                    Contact us-->
<!--                                </b-button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--        </div>-->
<!--    </section>-->
    <section class="has-background-black py-6">
        <div class="container has-text-centered px-3 py-6">
            <div class="card pre-footer">
                <div class="card-content has-text-centered">
                    <div class="columns">
                        <div class="column is-two-fifths is-fullwidth-mobile">
                            <div class="has-text-right">
                                <img src="img/macbook.png"/>
                            </div>
                        </div>
                        <div class="column is-three-fifths is-fullwidth-mobile is-flex is-align-items-center is-justify-content-center">
                            <div>
                                <div class="has-text-centered mb-6">
                                    <h3 class="title is-4 has-text-weight-semibold has-text-dark">
                                        Start your digital journey
                                    </h3>
                                    <p class="subtitle is-6 has-text-grey-dark">
                                        QLO is in the beta stage, but we would love to see what you <br> can create with it.
                                        Feel free to send us feedback.
                                    </p>
                                </div>
                                <div class="buttons is-justify-content-center">
                                    <b-button type="is-white"
                                              class="remove-shadow"
                                              rounded
                                              tag="a"
                                              href="https://qlo.morflax.com/app"
                                              target="_blank"
                                    >
                                        Try now for free
                                    </b-button>
                                    <b-button type="is-white" rounded @click="openModal">
                                        Join mailing list
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import EventBus from "../../plugins/EventBus";

    export default {
        name: "PreFooter",


        methods: {
            openModal() {
                EventBus.$emit('open-modal')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .pre-footer {
        background-image: linear-gradient(-9deg, #b1c1d6 1.5%, #efefef 100.3%);
    }

    img {
        width: auto;
        max-height: 350px;
    }
</style>
