<template>
    <div class="main-hero">
        <section class="hero section section-2-2 is-fullheight-with-navbar">
            <div class="hero-body section-content-2-2 is-align-items-flex-end">
                <div class="container">
                    <div class="columns">
                        <div class="column is-two-thirds has-text-left is-offset-5-desktop">
                            <h3 class="subtitle is-4 is-size-5-mobile has-text-black">
                                01.
                            </h3>
                            <h1 class="title has-text-weight-semibold is-1 is-size-3-mobile has-text-black">
                                Choose <br> your avatar
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero section section-2-3 is-fullheight-with-navbar ">
            <div class="hero-body section-content-2-3 is-align-items-flex-end">
                <div class="container">
                    <div class="columns">
                        <div class="column is-half has-text-left is-offset-5-desktop">
                            <h3 class="subtitle is-4 is-size-5-mobile has-text-black">
                                02.
                            </h3>
                            <h3 class="title has-text-weight-semibold is-1 is-size-3-mobile has-text-black">
                                Customize clothing
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero section section-2-4 is-fullheight-with-navbar">
            <div class="hero-body section-content-2-4 is-align-items-flex-end">
                <div class="container">
                    <div class="columns">
                        <div class="column is-half has-text-left is-offset-5-desktop">
                            <h3 class="subtitle is-4 is-size-5-mobile has-text-black">
                                03.
                            </h3>
                            <h3 class="title has-text-weight-semibold is-1 is-size-3-mobile has-text-black">
                                Upload your <br> own design
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero section section-2-5 is-fullheight-with-navbar">
            <div class="hero-body section-content-2-5 is-align-items-flex-end">
                <div class="container">
                    <div class="columns">
                        <div class="column is-half has-text-left is-offset-5-desktop">
                            <h3 class="subtitle is-4 is-size-5-mobile has-text-black">
                                04.
                            </h3>
                            <h3 class="title has-text-weight-semibold is-1 is-size-3-mobile has-text-black">
                                Customize background
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "pre-about-hero",

        mounted () {
            this.$nextTick(this.pinScene)
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                // tl.from(
                //     `section.section-2-2`,
                //     1,
                //     {opacity: 0},
                // );

                tl.fromTo(
                    `.section-content-2-2`,
                    5,
                    {y: 5},
                    {y: -5, ease: this.$gsap.Linear.easeNone},
                );

                tl.to(
                    `.section-content-2-2`,
                    1,
                    {opacity: 0, delay: 0.5},
                    '-=1'
                );

                tl.from(
                    `section.section-2-3`,
                    1,
                    {opacity: 0},
                );

                tl.fromTo(
                    `.section-content-2-3`,
                    5,
                    {y: 5},
                    {y: -5, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.to(
                    `.section-content-2-3`,
                    1,
                    {opacity: 0, delay: 0.5},
                    '-=1'
                );

                tl.from(
                    `section.section-2-4`,
                    1,
                    {opacity: 0},
                );

                tl.fromTo(
                    `.section-content-2-4`,
                    5,
                    {y: 5},
                    {y: -5, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.to(
                    `.section-content-2-4`,
                    1,
                    {opacity: 0, delay: 0.5},
                    '-=1'
                );

                tl.from(
                    `section.section-2-5`,
                    1,
                    {opacity: 0},
                );

                tl.fromTo(
                    `.section-content-2-5`,
                    5,
                    {y: 5},
                    {y: -5, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                // tl.to(
                //     `.main-hero`,
                //     1,
                //     {opacity: 0, delay: 0.5},
                //     '-=1'
                // );


                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.pinContainer2-2',
                    triggerHook: 'onLeave',
                    duration: `200%`,
                })
                    .setPin('.pinContainer2-2')
                    // .setPin('.pinContainer2-2')
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer2-2Scene', scene);
            }
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    .subtitle {
        font-family: 'Teko', Helvetica, Arial, sans-serif;
    }

    .section-2-2 {
        background-image: url('/img/mockups_2/mockup1.jpg');
        background-position: center;
        background-size: cover;
    }

    .section-2-3 {
        background-image: url('/img/mockups_2/mockup2.jpg');
        background-position: center;
        background-size: cover;
    }

    .section-2-4 {
        background-image: url('/img/mockups_2/mockup3.jpg');
        background-position: center;
        background-size: cover;
    }

    .section-2-5 {
        background-image: url('/img/mockups_2/mockup4.jpg');
        background-position: center;
        background-size: cover;
    }

    @media only screen and (min-width: 768px) {
        .hero {
            .hero-body {
                align-items: center!important;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .section-2-2 {
            background-image: url('/img/mockups_2_mobile/mockup1.jpg');
            background-position: center;
            background-size: cover;
        }

        .section-2-3 {
            background-image: url('/img/mockups_2_mobile/mockup2.jpg');
            background-position: center;
            background-size: cover;
        }

        .section-2-4 {
            background-image: url('/img/mockups_2_mobile/mockup3.jpg');
            background-position: center;
            background-size: cover;
        }

        .section-2-5 {
            background-image: url('/img/mockups_2_mobile/mockup4.jpg');
            background-position: center;
            background-size: cover;
        }
    }

    .hero-body {
        width: 100%;
    }

    .column-text {
        align-items: center;
        display: flex;
    }

    /*.features {*/
    /*    img {*/
    /*        height: 160px;*/
    /*    }*/
    /*}*/

    .feature-content {
        img {
            width: 150px;
        }

        border: 1px solid #fff;
        border-radius: 15px;
        padding: 1.25rem;
    }

    @media only screen and (max-width: 768px) {
        .about-hero {
            .hero-body {
                align-items: center!important;
            }
        }

        .feature-content {
            img {
                width: 100px;
            }

            border: none;
            border-radius: 15px;
            padding: 0;
        }
    }
</style>
