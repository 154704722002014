<template>
  <div id="app">
    <transition name="fade">
      <div v-if="loaded">
        <navbar></navbar>
        <sections></sections>
      </div>
    </transition>
  </div>
</template>

<script>
    import Navbar from "./components/Navbar";
    import Sections from "./components/Sections";

    export default {
      name: 'App',

      data() {
        return {
          loaded: false,
        };
      },

      components: {
        Sections,
        Navbar,
      },

      mounted() {
        this.loaded = true;
      },

      computed: {
        isMobile() {
          if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
          } else {
            return false
          }
        }
      },
    }
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
