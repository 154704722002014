import './assets/scss/app.scss'
import 'remixicon/fonts/remixicon.css'

import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import KsVueScrollmagic from 'ks-vue-scrollmagic';

const customIconPacks = {
  'fas': {
    sizes: {
      'default': '',
      'is-small': 'fa-xs',
      'is-medium': 'fa-lg',
      'is-large': 'fa-2x'
    }
  },
  'ri': {
    sizes: {
      'is-small': 'ri-lg',
      'default': 'ri-1x',
      'is-medium': 'ri-lg',
      'is-large': 'ri-3x'
    },
    iconPrefix: 'ri-',
  },
};

Vue.use(Buefy, {
  customIconPacks: customIconPacks,
  defaultIconPack: 'ri'
});
Vue.use(KsVueScrollmagic);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
