<template>
    <div class="image-sequence is-hidden-mobile" ref="pin"></div>
</template>

<script>
    /* eslint-disable */
    import lottie from "lottie-web";
    import AnimationData from "../../assets/json/data";

    export default {
        mounted () {
            this.$nextTick(this.pinContainerScene)
        },

        methods: {
            pinContainerScene () {
                this.anim = lottie.loadAnimation({
                        container: this.$refs.pin,
                        loop: false,
                        autoplay: false,
                        animationData: AnimationData,
                        rendererSettings: { progressiveLoad:false, preserveAspectRatio: 'xMidYMax slice' },
                    }
                );

                // this.anim.addEventListener("data_ready", this.complete);

                const avatar = new this.$gsap.TimelineMax();
                const vm = this;

                avatar.to({
                    frame: 0
                }, 3, {
                    frame: this.anim.totalFrames - 1,
                    onUpdate: function () {
                        vm.anim.goToAndStop(Math.round(this.target.frame), true)
                    },
                    ease: this.$gsap.Linear.easeNone
                });

                avatar.fromTo(
                    '.image-sequence',
                    0.3,
                    {top: 0, x: '0%', y: '0%', opacity: 1},
                    {opacity: 0, ease: this.$gsap.Linear.easeOut},
                    '-=0.1'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.image-sequence',
                    triggerHook: 'onLeave',
                    duration: '220%'
                })
                    .setPin('.image-sequence')
                    .setTween(avatar);

                this.$ksvuescr.$emit('addScene', 'imageSequenceScene', scene);
            },

            complete() {
                $('#sec svg').attr('preserveAspectRatio','xMidYMax slice');
            }
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style scoped>
    .image-sequence {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        /*position: relative;*/
        position: absolute;
        top: 0!important;
    }
</style>
