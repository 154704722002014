<template>
    <div>
        <section class="about-0-hero section-3 has-background-white">
            <div class="is-align-items-flex-end p-0">
                <div class="container has-text-centered">
                    <div class="has-text-centered">
                        <h3 class="title has-text-weight-semibold is-3 has-text-black">
                            Rent digital avatar
                        </h3>
                        <p class="has-text-grey-darker mx-3">
                            Our digital avatars can fit into your clothing brand, just upload your design, <br>
                            adjust settings and download your individual images directly in your Browser
                        </p>
                    </div>
                    <div class="is-flex is-justify-content-center my-6">
                        <div class="avatar-card card-1">
                            <img src="img/model_x_black.png"/>
                            <h5 class="title is-5 has-text-black">Model X <b-tag>Free</b-tag></h5>
                        </div>
                        <div class="avatar-card card-2">
                            <img src="img/model_y_black.png"/>
                            <h5 class="title is-5 has-text-black">Model Y <b-tag>Free</b-tag></h5>
                        </div>
                    </div>
                    <b-button type="is-black"
                              rounded
                              outlined
                              tag="a"
                              href="https://qlo.morflax.com/app"
                              target="_blank"
                              class="mt-5"
                    >
                        Get started, it's free
                    </b-button>
                </div>
            </div>
        </section>
        <section class="section-3-2 has-background-white py-6">
            <div class="is-align-items-flex-end p-0 mx-3">
                <div class="container has-text-centered">
                    <h3 class="title has-text-weight-semibold is-3 has-text-black mb-6">
                        It’s as easy <br>
                        as it looks.
                    </h3>
                    <div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_tvriglg33s videoFoam=true"
                                 style="height:100%;position:relative;width:100%;-webkit-box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);border-radius: 15px;"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "about-intro-hero",

        mounted () {
            if (!this.isMobile) {
                this.$nextTick(this.pinScene);
                this.$nextTick(this.pinScene2);
            }
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                tl.from(
                    `section.section-2-1`,
                    1,
                    {opacity: 0},
                );

                tl.fromTo(
                    `.card-1`,
                    1,
                    {x: -150},
                    {x: 0, ease: this.$gsap.Linear.easeNone},
                );

                tl.fromTo(
                    `.card-2`,
                    1,
                    {x: 150},
                    {x: 0, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-3',
                    triggerHook: '1',
                    duration: '100%',
                    offset: '-100%'
                })
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer2-1Scene', scene);
            },

            pinScene2() {
                const tl = new this.$gsap.TimelineMax();

                tl.fromTo(
                    `.app-card`,
                    1,
                    {y: 25},
                    {y: -50, ease: this.$gsap.Linear.easeNone},
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.section-3-2',
                    triggerHook: '2',
                    duration: '200%',
                })
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer2-2Scene', scene);
            }
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    .about-0-hero {
        margin-top: 25%;
        padding-top: 7%;
        padding-bottom: 7%;
        position: relative;
    }

    .avatar-card {
        width: 470px;
        margin-left: 25px;
        margin-right: 25px;
    }

    @media only screen and (max-width: 768px) {
        .avatar-card {
            width: 70%;
            /*width: 300px;*/
        }

        .about-0-hero {
            margin-top: 0;
            padding-top: 15%;
            padding-bottom: 7%;
        }
    }

    .hero-body {
        width: 100%;
    }

    .column-text {
        align-items: center;
        display: flex;
    }

    /*.app-card {*/
    /*    -webkit-box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);*/
    /*    box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);*/
    /*}*/

    .w-chrome {
        -webkit-box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);
        box-shadow: 4px 12px 40px 6px rgba(0,0,0,0.09);
        border-radius: 15px;
    }
</style>
