<template>
    <section class="section-11 py-6 has-background-white">
        <div class="section-content-11 py-6">
            <div class="container has-text-centered px-3">
                <div>
<!--                    <p class="is-6 my-6 has-text-grey-dark">-->
<!--                        QLO is still very early but, we would love to see what you can build with it at-->
<!--                        <br> this stage! Feel free to tag us on Twitter or IG with your work.-->
<!--                    </p>-->
                    <h4 class="title is-4 has-text-weight-semibold has-text-black">
                        Upcoming features
                    </h4>
                </div>
                <div class="columns mt-5">
                    <div class="column is-one-third">
                        <div class="card">
                            <div class="card-content">
                                <div class="has-text-left has-text-grey-dark">
                                    <b-tag type="is-dark is-light" size="is-medium">Coming soon</b-tag>
<!--                                    <b-icon icon="lightbulb-line" size="is-large" type="is-white"></b-icon>-->
                                    <h4 class="subtitle is-4 has-text-weight-bold has-text-black mt-3">
                                        Pose control & animation
                                    </h4>
                                    <p>Easily customize digital avatar poses. <br> 10 different poses.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-one-third">
                        <div class="card">
                            <div class="card-content">
                                <div class="has-text-left has-text-grey-dark">
                                    <b-tag type="is-dark is-light" size="is-medium">Coming soon</b-tag>
<!--                                    <b-icon icon="lightbulb-line" size="is-large" type="is-white"></b-icon>-->
                                    <h4 class="subtitle is-4 has-text-weight-bold has-text-black mt-3">
                                        Light control & customization
                                    </h4>
                                    <p>Easily customize Lighting. <br> Change color, rotation, size...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-one-third">
                        <div class="card">
                            <div class="card-content">
                                <div class="has-text-left has-text-grey-dark">
                                    <b-tag type="is-dark is-light" size="is-medium">Coming soon</b-tag>
<!--                                    <b-icon icon="lightbulb-line" size="is-large" type="is-white"></b-icon>-->
                                    <h4 class="subtitle is-4 has-text-weight-bold has-text-black mt-3">
                                        Photo realistic rendering
                                    </h4>
                                    <p>Turn on photo realistic rendering and get high quality realistic images.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "upcoming",

        data() {
            return {
                show: false,
            }
        },

        mounted() {
            this.show = true;

            // this.$nextTick(this.pinScene)
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                // tl.from(
                //     `section.section-11`,
                //     1,
                //     {opacity: 0},
                // );

                tl.fromTo(
                    `.section-content-11`,
                    5,
                    {y: 25},
                    {y: -25, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.to(
                    `section.section-11`,
                    1,
                    {opacity: 0, delay: 0.5},
                    '-=1'
                );


                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.pinContainer5',
                    triggerHook: 'onLeave',
                    duration: `100%`,
                })
                    .setPin('.pinContainer5')
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer5Scene', scene);
            },

            getAvatarImage(avatar) {
                return require('../../../public/img/' + avatar.image);
            },

            resolveCardStyle(avatar) {
                let components = [];

                if (avatar.selected) {
                    components.push('card-active')
                }

                components.push(avatar.name);

                return components;
            },

            openBeta() {
                // TODO: temp solution
                // window.open(`https://qlo.morflax.com/app/#/customize/${name}`)

                window.open('https://morflax.com/qlo-beta-access/');
            },
        },

        destroyed () {
            this.$ksvuescr.$emit('destroy')
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        background-color: whitesmoke;
    }
</style>
