<template>
    <section class="hero section section-2 is-fullheight-with-navbar">
        <div class="hero-body section-content-2">
            <h1 class="title is-1 is-size-3-mobile has-text-weight-semibold has-text-centered has-text-left-mobile">
                A new way to showcase <br> your clothing designs
            </h1>
        </div>
    </section>
</template>

<script>
    export default {
        name: "post-main-hero",

        mounted () {
            if (!this.isMobile) {
                this.$nextTick(this.pinScene);
            }
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            pinScene() {
                const tl = new this.$gsap.TimelineMax();

                tl.from(
                    `section.section-2`,
                    1,
                    {opacity: 0},
                    // '+=0.5'
                );

                tl.fromTo(
                    `.section-content-2`,
                    5,
                    {y: 25},
                    {y: -25, ease: this.$gsap.Linear.easeNone},
                    '-=1'
                );

                tl.to(
                    `section.section-2`,
                    2,
                    {opacity: 0, delay: 0.5},
                    '-=2'
                );

                const scene = new this.$scrollmagic.Scene({
                    triggerElement: '.pinContainer2',
                    triggerHook: 'onLeave',
                    duration: `110%`,
                })
                    .setPin('.pinContainer2', {pushFollowers: false})
                    .setTween(tl);

                this.$ksvuescr.$emit('addScene', 'pinContainer2Scene', scene);

            }
        }
    }
</script>

<style scoped>

</style>
