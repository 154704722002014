<template>
    <div>
        <image-sequence v-if="!isMobile"></image-sequence>
        <div>
            <div class="pinContainer" ref="pin">
                <main-hero></main-hero>
            </div>
            <div v-if="!isMobile" class="pinContainer2" ref="pin">
                <post-main-hero></post-main-hero>
            </div>
            <div class="pinContainer2-1" ref="pin">
                <about-intro-hero></about-intro-hero>
            </div>
            <div class="pinContainer2-2" ref="pin">
                <pre-about-hero></pre-about-hero>
            </div>
            <div class="pinContainer3" ref="pin">
                <where-to-use></where-to-use>
                <about-hero></about-hero>
            </div>
            <upcoming></upcoming>
<!--            <selection-hero></selection-hero>-->
            <pre-footer></pre-footer>
            <main-footer></main-footer>
        </div>
        <subscription-modal></subscription-modal>
        <div class="sticky-footer is-flex is-justify-content-flex-end pr-3 pb-1">
            <a href="https://www.producthunt.com/posts/qlo?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-qlo" target="_blank">
                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=278346&theme=dark" alt="QLO - Generate custom 3D digital clothing mockups in your browser. | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" />
            </a>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import MainHero from "./sections/MainHero";
    import SelectionHero from "./sections/SelectionHero";
    import AboutHero from "./sections/AboutHero";
    import ImageSequence from "./sections/ImageSequence";
    import PostMainHero from "./sections/PostMainHero";
    import MainFooter from "./sections/MainFooter";
    import PreFooter from "./sections/PreFooter";
    import AboutIntroHero from "./sections/AboutIntroHero";
    import PreAboutHero from "./sections/PreAboutHero";
    import Upcoming from "./sections/Upcoming";
    import WhereToUse from "./sections/WhereToUse";
    import SubscriptionModal from "./sections/SubscriptionModal";

    export default {
        name: 'sections',

        components: {
            SubscriptionModal,
            WhereToUse,
            ImageSequence,
            MainHero,
            PostMainHero,
            AboutIntroHero,
            PreAboutHero,
            AboutHero,
            // AppHero,
            SelectionHero,
            MainFooter,
            PreFooter,
            Upcoming,
        },

        computed: {
            isMobile() {
                if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            }
        },
    }
</script>

<style lang="scss">
    .pinContainer {
        width: 100%!important;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    .pinContainer2 {
        width: 100%!important;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    .pinContainer2-2 {
        width: 100%!important;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    .pinContainer4 {
        width: 100%!important;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    .section {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left:0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }

    @media only screen and (max-width: 768px) {
        .section {
            padding: 3rem 0.5rem;
        }
    }

    .hero {
        background-color: transparent;
    }

    .backContainer {
        width: 100%!important;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    .sticky-footer {
        position: fixed;
        bottom: 0;
        float: right;
        right: 0;
    }
</style>
